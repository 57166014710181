const conditionsGenerales = {
  section1: {
    title: `Conditions générales d’utilisation`,
    description: `Vous trouverez ici les conditions générales de votre compte Dropzik. En ouvrant votre propre compte, vous acceptez ces termes et conditions ainsi que la politique de confidentialité associée et ce dans son intégralité.`,
  },
  section2: {
    title: `Définitions`,
    items: [
      {
        title: `Nous`,
        description: `Dropzik Digital INC, Canada.`
      },
      {
        title: `Magasins`,
        description: `les fournisseurs de services numériques comme Spotify, YouTube, Apple Music ou même Shazam.`
      },
      {
        title: `Utilisation de votre compte`,
        description: `nous définissons comme "utilisation" le moment à partir duquel vous vous connectez à votre compte.`
      },
      {
        title: `Partenaire`,
        description: `cela comprend les prestataires de services techniques, les agrégateurs, les sociétés de services bancaires et financiers, les sociétés de recouvrement, etc.`
      },
      {
        title: `Site Dropzik`,
        description: `le site internet/ plateforme Dropzik qui héberge votre compte Dropzik.`
      },
      {
        title: `Services`,
        description: `il s’agit des services proposés sur le site internet Dropzik.`
      }
    ]
  },
  section3: {
    title: `Garanties`,
    items: [
      `Vous garantissez que vous avez plus de 18 ans.`,
      `Vous garantissez que votre adresse électronique (e-mail) est à jour.`,
      `Vous garantissez que vous êtes responsable de la conservation de vos données de connexion.`,
      `Vous garantissez que votre compte Dropzik ne sera pas utilisé pour des activités frauduleuses ou à des fins autres que celles prévues par les services de Dropzik.`,
    ]
  },
  section4: {
    title: `Paiements`,
    items: [
      `Nous nous réservons le droit de facturer des frais pour certains modes de paiement. Ces frais seront spécifiquement indiqués avec la possibilité de poursuivre ou d’annuler votre commande.`,
      `Nous nous réservons le droit de facturer des pénalités de retard pour les factures impayées.`
    ],
  },
  section5: {
    title: `Comptabilité et versements`,
    items: [
      `Vous pouvez initier les paiements vous-même.`,
      `Une fois qu’un paiement a été initié, les paiements seront effectués sous 30 jours.`,
      `Seuls les revenus que nous avons reçus pour vos ventes et redevances seront versés.`,
      `Nous nous réservons le droit de déduire les coûts tels que les « retours » (un client retourne un titre à un magasin et demande un remboursement) de votre compte.`,
      `L’ensemble des frais de transfert de paiement seront à votre charge.`
    ]
  },
  section6: {
    title: `Crédits « en espèce » (Cash credit) et crédits promotionnels (Promo credit)`,
    items: [
      `Le crédit en espèce (normalement des ventes et des redevances) peut être retiré par vous-même à tout moment en demandant un virement, ou peut être utilisé pour payer d’autres services Dropzik (en partie ou en totalité). Votre crédit de caisse n’expire pas tant que vous vous connectez au moins une fois par an, après quoi nous nous réservons le droit de convertir le crédit d’espèce en crédits promotionnels (Promo-crédit) pour minimiser le risque de change.`,
      `Le crédit promotionnel peut être utilisé pour régler certains services, mais ne peut pas être transformé en crédit en espèce. Il n’expire pas tant que vous vous connectez à votre compte au moins une fois par an. Dans tous les cas, vous serez averti par e-mail de toute expiration approchante.`
    ]
  },
  section7: {
    title: `Remboursement`,
    description: `Nous avons une politique de non-remboursement en espèces, et les remboursements seront effectués sous forme de crédits promotionnels.`,
  },
  section8: {
    title: `Fermeture de votre compte Dropzik`,
    description: `Vous pouvez fermer votre compte Dropzik à tout moment, à condition que toutes les factures impayées aient été réglées. 
      La clôture du compte déclenchera la résiliation automatique de tous les services en suspens, de sorte que les versions distribuées seront supprimées, etc. 
      Il est possible que certains services, comme la publication en ligne, soient assujettis à une durée minimale, mais A) vous aurez été averti de ces modalités au moment de l’achat du service et B) les services expireront automatiquement après l’expiration de la durée minimale.
      Toutes les données à caractère personnel téléchargées seront effacées
      conformément à la réglementation Canadienne.`,
  },
  section9: {
    title: `Activité frauduleuse`,
    description: `Nous nous réservons le droit de bloquer votre compte si nous soupçonnons une activité frauduleuse ou si une réclamation a été émise contre vous. Le compte sera débloqué dès que nous aurons eu le temps de recueillir toutes les informations pertinentes nous menant à une décision éclairée.
      Lorsqu’il est établi que vous avez enfreint les droits d’autrui ou que vous avez participé à des activités frauduleuses, nous nous réservons le droit de vous identifier auprès des parties lésées et/ou des autorités compétentes. Ce droit prévaut sur tout droit à la vie privée énoncé dans notre politique de confidentialité. De plus, vous pourriez être tenu de rembourser tous les paiements et/ou de renoncer aux paiements impayés.`
  },
  section10: {
    title: `Modification des conditions d’utilisation`,
    description: `Nous nous réservons le droit de mettre à jour les conditions d’utilisation à tout moment, et de telles modifications peuvent être communiquées de plusieurs façons, y compris par courrier électronique à l’adresse d’origine entrée lors de l’ouverture de votre compte.
      Toute utilisation de votre compte après notification sera considérée comme une acceptation des modifications.
      L’illégalité ou l’inopposabilité de toute partie de l’accord n’affecte pas la légalité et l’opposabilité de l’accord dans son ensemble.`
  },
  section11: {
    title: `Droit applicable`,
    description: `Les présentes conditions d’utilisation tant dans leur interprétation que dans leur application sont soumises au droit Canadien tel qu’appliqué à Montréal, au Canada.`
  }
};

export default conditionsGenerales;